.about {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: auto;
  justify-content: center;
  padding-top: 4vh;
  margin-bottom: -3vh;
}

.aboutText {
  width: 30vw;
  padding-top: 5vw;
  /* background-color: red; */
}
.aboutText p {
  text-align: justify;
  /* text-justify: inter-word; */
}
.aboutText h2 {
  line-height: 50%;
  color: #305f72;
}

.aboutImage {
  width: 30%;
}

.image {
  padding: 2vw;
  width: 90%;
  object-fit: contain;
  height: 90%;
}

@media (max-width: 1024px) {
  /* .about {
    flex-direction: column;
  }

  
  .aboutImage {
    text-align: center;
    width: 100%;
  } */

  .aboutText {
    margin: auto;
    width: 40vw;
    /* padding: 3vw; */
  }
  .aboutImage {
    text-align: center;
    width: 50%;
  }
  .image {
    padding: 2vw;
    /* width: 90%; */
    /* object-fit: contain; */
    /* height: 90%; */
  }
}

@media (max-width: 768px) {
  .about {
    flex-direction: column;
  }

  .aboutText {
    margin: auto;
    width: 100%;
    padding: 3vw;
  }

  .aboutImage {
    text-align: center;
    width: 100%;
  }
}
