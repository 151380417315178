.logo {
  max-width: 5vw;
}
.header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: white;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark {
  color: #305F72 !important;
}

@media (max-width: 768px) {
  .logo {
    max-width: 15vw;
  }
}
