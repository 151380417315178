.footerInfo {
  /* background-color: #b4c9e0; */
  background-color: #305f72;
}
.footerInfoBase {
  width: 100%;
  margin: auto;
}

.footerImageBase {
  width: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
}

.contact_text {
  font-weight: 700;
  color: white;
}

.footerImage {
  margin-top: auto;
  max-width: 70vw;
}

.footer {
  padding-top: 4vh;
}

.footerLogo {
  max-width: 5vw;
  object-fit: contain;
  /* transform: translateY(5px); */
}

.footerBaseInner {
  width: 90%;
  margin: auto;
  /* margin-bottom: -30px; */
  /* padding-bottom: 2vh; */
  /* transform: translateY(-10px); */
}

.email_text {
  color: lightgrey;
}

.footerBaseInner h4 {
  line-height: 0%;
  font-weight: 400;
}
.footerRow {
  display: flex;
  width: 100%;
  align-items: center;
  line-height: 0%;

  justify-content: space-between;
}

.row {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  flex-direction: row;
}
.footerCellCopy {
  margin: auto;
}
.footerCell h5 {
  color: white !important;
  /* font-weight: 400; */
}

.footerRow p {
  padding-top: 5px;
  color: white !important;
  /* font-weight: 400; */
}

@media (max-width: 1280px) {
  .footer {
    padding-top: 4vh;
  }
  .footerCell {
    width: 100%;
  }
  .footerBaseInner {
    padding-top: 3vh;

    transform: translateY(0px);
    width: 90%;
  }
  .footerRow {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    /* padding-bottom: 2vh; */
  }
  .footerLogo {
    padding-right: 2vw;
    max-width: 8vw;
  }
}

@media (max-width: 1024px) {
  .footer {
    padding-top: 1vh;
  }
  .footerCell {
    width: 100%;
  }
  .footerBaseInner {
    width: 90%;
  }
  .footerRow {
    display: flex;
    width: 90%;
    padding-top: 2vh;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  .footerLogo {
    padding-right: 2vw;
    max-width: 10vw;
  }
}

@media (max-width: 768px) {
  .footerImage {
    max-width: 90vw;
    margin: auto;
  }

  .footerImageBase {
    height: 30vh;
  }

  .footer {
    padding-top: 1vh;
  }
  .footerCell {
    width: 100%;
  }
  .footerBaseInner {
    width: 90%;
    padding-top: 1vh;
  }
  .footerCellCopy {
    transform: translateY(1vh);
  }
  .row {
    flex-direction: column;
  }
  .footerRow {
    padding-top: 0vh;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    line-height: normal;
  }
  .footerLogo {
    display: none;
    max-width: 15vw;
  }
}
