.info {
  display: flex;
  flex-direction: row;
  /* background-color: #b4c9e0; */
  background-color: #305F72; 
  margin-bottom: 4vh;
  height: 40vh;
  align-items: center;
  /* transform: translateY(-3vh); */
  justify-content: center;
}

.infoText h1 {
    width: 40vw;
    padding: 3vw;
    color: #fff;
}
.infoText p {
    color: white;
    width: 30vw;
    text-align: justify;
    text-justify: inter-word;
    font-size: 20px;
}
.curve {
  background-color: white;
  clip-path: ellipse(100% 135% at 0% 0%);
  transform: translateY(95%) scaleX(-1);
  width: 100%;
  height: 5vh;
}
.infoImage {
  width: 30%;
}

@media (max-width: 1024px) {
  .info {
    flex-direction: column;
    min-height: 60vh;
  }

  .infoText h1 {
    margin: auto;
    width: 90vw;
    padding-top: 3vw;
    text-align: center;
  }

  .infoText p {
    width: 70vw;
    text-align: justify;
    text-justify: inter-word;
  }

  .infoImage {
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .info {
    flex-direction: column;
    min-height: 60vh;
  }

  .infoText h1 {
    margin: auto;
    width: 90vw;
    padding-top: 3vw;
    text-align: center;
  }

  .infoText p {
    width: 70vw;
    text-align: justify;
    text-justify: inter-word;
  }

  .infoImage {
    text-align: center;
    width: 100%;
  }
  .curve {
    clip-path: ellipse(100% 105% at 0% 0%);
  }
}
