.useCases {
  width: 100%;
  padding-top: 4vh;
  padding-bottom: 4vh;
  background-color: #B5C9E0;
}
.useCasesBase {
  margin: auto;
  width: 80%;
}

.useCasesBase h1 {
  color: #305f72;
}
.useCasesGrid {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  padding-top: 4vh;
  padding-bottom: 4vh;
}
.useCaseImageBase {
  width: 50%;
  text-align: center;
}
.useCaseImage {
  width: 90%;
  object-fit: contain;
  height: 90%;
}

@media (max-width: 768px) {
  .useCasesGrid {
    width: 100%;
    flex-direction: column;
  }

  .useCaseImageBase {
    text-align: center;
    width: 100%;
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
}
